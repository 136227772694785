import { isExperimentOpen } from '@wix/thunderbolt-commons'
import skinsDefaults from './skinsDefaults.json'
import { Experiments } from '@wix/thunderbolt-symbols'
import { TYPE_ALIASES } from './constants'

export type SkinDefaultsData = {
	types: Record<string, string>
	defaults: Record<string, string>
}

export const skinsInfo = skinsDefaults as Record<string, SkinDefaultsData>

const getType = (type: string) => TYPE_ALIASES[type] || type

function resolveAliasesFromSkinsInfo(unresolvedAliasesSkinsInfo: Record<string, SkinDefaultsData>) {
	return Object.entries(unresolvedAliasesSkinsInfo).reduce((acc, [compType, raw]) => {
		acc[compType] = {
			defaults: raw.defaults,
			types: Object.fromEntries(Object.entries(raw.types).map(([name, rawType]) => [name, getType(rawType)])),
		}
		return acc
	}, {} as Record<string, SkinDefaultsData>)
}

export function mergeWithExternalSkinsInfo(
	overrideSkinsInfo: Record<string, SkinDefaultsData>,
	experiments: Experiments
) {
	const shouldMerge =
		isExperimentOpen('specs.thunderbolt.mergeSkinDefaultsFromRegistry', experiments) ||
		isExperimentOpen('specs.thunderbolt.mergeSkinDefaultsFromRegistry_loggedIn', experiments)
	if (!shouldMerge) {
		return skinsInfo
	}
	return {
		...skinsInfo,
		...resolveAliasesFromSkinsInfo(overrideSkinsInfo),
	}
}
