import _ from 'lodash'
import { ContainerModuleLoader, withDependencies } from '@wix/thunderbolt-ioc'
import {
	LoggerSymbol,
	ILogger,
	IRendererPropsExtender,
	RendererPropsExtenderSym,
	LOADING_PHASES,
} from '@wix/thunderbolt-symbols'
import { create } from '@wix/fe-essentials-viewer-platform/fedops'
import type { Environment } from '@wix/thunderbolt-environment'
import { createFedopsLogger, multipleIncludes, createNoopLogger } from '@wix/thunderbolt-commons'
import * as Sentry from '@wix/fe-essentials-viewer-platform/sentry'
// @ts-ignore
import { createLoggerApi } from '@wix/thunderbolt-logger'
import { DSViewerApiFactoryParams } from '../dsClientRunner'

export type DsLoggerIntegrations = {
	params: DSViewerApiFactoryParams & { isViewerFragment?: boolean }
}
const DEV_QUERY_PARAMS = [
	'ReactSource',
	'viewerSource',
	'EditorSource',
	'experiments',
	'WixCodeRuntimeSource',
	'debug',
	'debugViewer',
	'isWixCodeIntegration',
	'isqa',
	'suppressbi=true',
]

const getEnvironment = (is_rollout: number | boolean | null) => {
	if (typeof is_rollout === 'undefined' || is_rollout === null) {
		return 'canary'
	}

	return is_rollout ? 'rollout' : 'production'
}

const extractVersion = (base: string) => base.split('/').pop()

export function createLogger(dsLoggerIntegrations: DsLoggerIntegrations): ILogger {
	const { params } = dsLoggerIntegrations
	const {
		dmBase,
		editorSessionId,
		runningExperiments,
		originalTemplateId,
		isViewerFragment,
		externalBaseUrl,
		wixBiSession,
		metaSiteId,
		isPreview,
		isResponsive,
	} = params

	// Read the url of the editor (i.e. the parent window) otherwise use the url of the preview
	const editorUrl = window!.document.referrer || window!.location.href
	const shouldSendByUrls = multipleIncludes(editorUrl, DEV_QUERY_PARAMS)

	if ((!window.Sentry || shouldSendByUrls) && !editorUrl.includes('forceReport')) {
		return createNoopLogger()
	}

	const biLoggerFactory = params.fedopsFactory
	const fedopsLogger = createFedopsLogger({
		biLoggerFactory,
		phasesConfig: 'SEND_START_AND_FINISH',
		presetType: 'DS',
		appName: isViewerFragment ? 'thunderbolt_minisite' : 'thunderbolt',
		factory: create,
		experiments: runningExperiments,
		monitoringData: {
			metaSiteId,
			dc: 'unknown_ds_dc',
			isCached: !!wixBiSession.isCached,
			isHeadless: false,
			rolloutData: {
				isTBRollout: !!wixBiSession.is_rollout,
				isDACRollout: !!wixBiSession.isDACrollout,
				siteAssetsVersionsRollout: !!wixBiSession.isSAVrollout,
			},
		},
	})
	const sentryStore = {
		// @ts-ignore no type in DM
		release: window.viewerSource,
		environment: getEnvironment(wixBiSession.is_rollout),
		user: `${params.userId}`,
	} as const

	const tbSentry = new Sentry.Hub(
		new Sentry.BrowserClient({
			dsn: `https://${
				isViewerFragment ? '2dfc0ead7fd244bcbff6ee3a19df1f26' : '90c5cfe04a314b9091d0ea7aef310cec'
			}@sentry-next.wixpress.com/70`,
		})
	)
	const logger = createLoggerApi({
		biLoggerFactory,
		fedopsLogger,
		// @ts-ignore no type for sentry loader
		sentry: tbSentry,
		sentryStore,
		errorLimit: 10000,
	})
	const globalExtra = {
		editorUrl: editorUrl + '&disableSave=true',
		userExplorer:
			'https://bo.wix.com/bi-ux/#/history?levels=1,2,3,4&fields=date_created,src,evid,evid_desc,browser_name,app_url,msid,panel_name,name,error_type,error_info,component_type&stop=' +
			(Date.now() + 120000) +
			'&timezone=0&uuid=' +
			params.userId +
			'&wql=msid%3D"' +
			metaSiteId +
			'"',
		externalBaseUrl,
		url: window.location.href,
		runningExperiments,
		editorSessionId,
	}
	logger.setGlobalsForErrors({
		tags: {
			dmBase: extractVersion(dmBase),
			isPreview,
			isResponsive,
			metaSiteId,
			editorSessionId,
			originalTemplateId,
			externalBaseUrl,
			origin: wixBiSession.origin,
		},
		extra: globalExtra,
	})
	tbSentry.configureScope((scope: any) => {
		scope.addEventProcessor((event: any, hint?: any) => {
			if (event.release && hint?.originalException?.message) {
				const { message, name } = hint.originalException
				if (event.level === 'error') {
					const extra = _.pickBy(event.extra, (value, key) => !(key in globalExtra))
					const tags = event.tags
					logger.meter('error', {
						paramsOverrides: {
							// @ts-ignore
							evid: 36,
							errorInfo: message,
							errorType: name,
							eventString: hint.event_id,
							tags,
							pn: tags.pageNumber,
							// @ts-ignore
							isFirstNavigation: tags.pageNumber === 1,
							pageId: tags.pageId,
							viewMode: tags.viewMode,
							// @ts-ignore
							extra,
							// @ts-ignore
							isFatal: extra.isFatal !== false,
							viewerFeature: tags.feature,
						},
					}) // this is a workaround to get error rate until we will have support for postgresSQL in fedonomy
				}
				return event
			}
			return null
		})
	})

	const phasesStatus = Object.values(LOADING_PHASES).reduce((acc, phaseName) => {
		return { ...acc, [phaseName]: 'init' }
	}, {} as Record<string, string>)

	return {
		...logger,
		phaseStarted: (phaseName: string, customParams?: object) => {
			if (phasesStatus[phaseName]) {
				if (phasesStatus[phaseName] === 'init') {
					phasesStatus[phaseName] = 'started'
					logger.phaseStarted(phaseName, customParams)
				}
				return
			}

			logger.phaseStarted(phaseName, customParams)
		},
		phaseEnded: (phaseName: string, customParams?: object) => {
			if (phasesStatus[phaseName]) {
				if (phasesStatus[phaseName] === 'started') {
					phasesStatus[phaseName] = 'done'
					logger.phaseEnded(phaseName, customParams)
				}
				return
			}

			logger.phaseEnded(phaseName, customParams)
		},
	}
}

const rendererPropsExtender = withDependencies(
	[LoggerSymbol],
	(logger: ILogger): IRendererPropsExtender => {
		return {
			async extendRendererProps() {
				return { logger }
			},
		}
	}
)

export const editor = ({ logger }: Environment): ContainerModuleLoader => (bind) => {
	bind(LoggerSymbol).toConstantValue(logger)
	bind(RendererPropsExtenderSym).to(rendererPropsExtender)
}
