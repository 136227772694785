import type { IPagesModelsRegistrar, PagesModelsFetcher } from '@wix/thunderbolt-symbols'

const notImplemented = (_pageIds: Array<string>, _viewMode?: 'MOBILE' | 'DESKTOP') => {
	throw new Error(
		'pagesModelsFetcher is not implemented - please register it with viewerApi.runtime.setPagesModelsFetcher'
	)
}

const pagesModelsRegistrarFactory = (): IPagesModelsRegistrar => {
	let pagesModelsFetcher: PagesModelsFetcher = notImplemented

	const setPagesModelsFetcher = (_pagesModelsFetcher: PagesModelsFetcher) => {
		pagesModelsFetcher = _pagesModelsFetcher
	}

	const fetchPagesModels = (pageIds: Array<string>, viewMode?: 'MOBILE' | 'DESKTOP') =>
		pagesModelsFetcher(pageIds, viewMode)

	return {
		setPagesModelsFetcher,
		fetchPagesModels,
	}
}

export const pagesModelsRegistrar = pagesModelsRegistrarFactory()
