export const TYPE_ALIASES: Record<string, string> = {
	BORDER_SIZE: 'SIZE',
	BORDER_SIZES: 'BORDER',
	BORDER_RADIUS: 'BORDER',
	BORDER_TOP_SIZE: 'SIZE',
	BORDER_BOTTOM_SIZE: 'SIZE',
	BORDER_LEFT_SIZE: 'SIZE',
	BORDER_RIGHT_SIZE: 'SIZE',
	PADDING_SIZE: 'SIZE',
	PADDING_TOP_SIZE: 'SIZE',
	PADDING_BOTTOM_SIZE: 'SIZE',
	PADDING_LEFT_SIZE: 'SIZE',
	PADDING_RIGHT_SIZE: 'SIZE',
	MARGIN_SIZE: 'SIZE',
	MARGIN_TOP_SIZE: 'SIZE',
	MARGIN_BOTTOM_SIZE: 'SIZE',
	MARGIN_LEFT_SIZE: 'SIZE',
	MARGIN_RIGHT_SIZE: 'SIZE',
	BG_SIZE: 'SIZE',
	WIDTH_SIZE: 'SIZE',
	HEIGHT_SIZE: 'SIZE',
	TOP_SIZE: 'SIZE',
	BOTTOM_SIZE: 'SIZE',
	LEFT_SIZE: 'SIZE',
	RIGHT_SIZE: 'SIZE',
	TEXT_SIZE: 'SIZE',
	TEXT_COLOR: 'COLOR',
	BORDER_COLOR: 'COLOR',
	COLOR_ALPHA: 'COLOR',
	BG_COLOR: 'COLOR',
	BG_COLOR_ALPHA: 'COLOR',
	BORDER_COLOR_ALPHA: 'COLOR',
	BOX_SHADOW_COLOR_ALPHA: 'COLOR',
	TEXT_COLOR_LEGACY_ALPHA: 'COLOR',
}
