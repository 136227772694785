import _ from 'lodash'
import React, { JSXElementConstructor, ReactElement } from 'react'
import type { GetViewerApiParams, ViewerAPI } from 'thunderbolt-viewer-manager-types'
import { named } from '@wix/thunderbolt-ioc'
import { ComponentsLoaderSymbol, IComponentsLoader } from '@wix/thunderbolt-components-loader'
import {
	ILogger,
	IStructureStore,
	Props,
	Structure as StructureSym,
	IPropsStore,
	Experiments,
	ExperimentsSymbol,
	PlatformSymbol,
	PlatformDsApi,
	CarmiInstance,
	PageFeatureConfigSymbol,
} from '@wix/thunderbolt-symbols'
import { createPromise, getPageBackgroundId } from '@wix/thunderbolt-commons'
import type { DsApiFactoryEnv, DsApis } from './getDsApis'
import { tbFragmentProps } from './types'
import { defineRepeaterCustomElement } from 'thunderbolt-components-react'
import { DSCarmi } from 'thunderbolt-ds-carmi-root/dist'
import { MASTER_PAGE_ID, Structure, ViewMode } from '@wix/thunderbolt-becky-types'
import { IPageInitializer, PageInitializerSymbol } from 'feature-pages'

declare const window: DsApiFactoryEnv['window']

export type GetViewerFragment = (
	hostReact: typeof React,
	serviceTopology: GetViewerApiParams['serviceTopology'],
	props: tbFragmentProps
) => Promise<ReactElement>

export type GetViewerFragments = (
	hostReact: typeof React,
	props: tbFragmentProps
) => Promise<Array<JSXElementConstructor<{ onReady: Function }>>>

export type GetViewerFragmentOnPage = (
	hostReact: typeof React,
	props: tbFragmentProps
) => Promise<JSXElementConstructor<{ onReady: Function }>>

export const VIEWER_FRAGMENT_RENDERER_INTERACTIONS = {
	GET_VIEWER_FRAGMENT_TOTAL: 'get_viewer_fragment_total',
	INIT_VIEWER_FRAGMENT: 'init_viewer_fragment',
	VIEWER_FRAGMENT_ADD_DATA: 'viewer_fragment_add_data',
	VIEWER_FRAGMENT_FULL_RENDER: 'viewer_fragment_full_render',
	VIEWER_FRAGMENT_RENDER: 'viewer_fragment_render',
	VIEWER_FRAGMENT_LOAD_COMPS: 'viewer_fragment_load_comps',
	VIEWER_FRAGMENT_LOAD_STYLES: 'viewer_fragment_load_styles',
	VIEWER_FRAGMENT_INIT_PAGE: 'viewer_fragment_init_page',
}

const onReadyComponents = ['ClassicSection', 'StripColumnsContainer', 'MediaContainer']

// We need this CSS in the preview frame for panel builder but this css breaks the editor css
// We don't need this css for miniSites (for now) so we are not copying it.
const isDesignSystemCssHref = (href: string) => href.includes('rb_dsgnsys')
const isMainDsCssHref = (href: string) => href.includes('main-renderer-ds') || href.includes('main-ds')

const shouldExcludeStyleSheetFromTop = (href: string) => isDesignSystemCssHref(href) || isMainDsCssHref(href)

const VIEWER_ADDITIONAL_CSS = 'VIEWER_ADDITIONAL_CSS'
const pointerEventsCss =
	'[data-mesh-id$="-gridContainer"] > *, [data-mesh-id$="-rotated-wrapper"] > *, [data-mesh-id$="inlineContent"] > :not([data-mesh-id$="-gridContainer"]) { pointer-events: auto; }'

const addViewerAdditionalCss = () => {
	const styleTag = document.createElement('style')
	styleTag.id = VIEWER_ADDITIONAL_CSS
	styleTag.appendChild(document.createTextNode(pointerEventsCss))
	window.top!.document.head.appendChild(styleTag)
}

const loadStylesForFragment = (
	targetRoot: HTMLHeadElement | ShadowRoot,
	shouldExcludeStyleSheet?: (href: string) => boolean
) => {
	const loadStylesPromises: Array<Promise<any>> = []
	document.head.querySelectorAll('link[rel="stylesheet"]').forEach((e) => {
		const href = e.getAttribute('href')

		if (!href || shouldExcludeStyleSheet?.(href)) {
			return
		}
		if (!targetRoot.querySelector(`link[href="${href}"]`)) {
			const newNode = e.cloneNode()
			const { promise, resolver } = createPromise()
			loadStylesPromises.push(promise)
			// @ts-ignore
			newNode.onload = resolver
			targetRoot.appendChild(newNode)
		}
	})
	if (!window.top!.document.getElementById(VIEWER_ADDITIONAL_CSS)) {
		addViewerAdditionalCss()
	}

	return loadStylesPromises
}

// Add masterPage component to the structure
const addMasterPageToMiniSites = (viewerAPI: ViewerAPI) => {
	let masterPageComp = viewerAPI.structure.getStructure(MASTER_PAGE_ID)
	if (!masterPageComp) {
		masterPageComp = {
			id: MASTER_PAGE_ID,
			type: 'Component',
			componentType: 'MasterPage',
			components: [],
			layout: {},
			parent: null,
			metaData: {
				pageId: 'masterPage',
			},
		}
	}

	viewerAPI.structure.updateStructure(MASTER_PAGE_ID, masterPageComp)
}

export const buildGetViewerFragment = (
	getDsApisPromise: () => Promise<DsApis>,
	logger: ILogger
): GetViewerFragment => async (hostReact, serviceTopology, props) => {
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.GET_VIEWER_FRAGMENT_TOTAL)
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.INIT_VIEWER_FRAGMENT)
	const { render, appDidMount, getViewerAPI, getModule } = await getDsApisPromise()
	const viewerAPI = getViewerAPI()
	const { structure, data, onReady, rootCompIds, extraEventsHandlers } = props
	logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.INIT_VIEWER_FRAGMENT)
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_ADD_DATA)

	const updatedRootCompIds = [MASTER_PAGE_ID, ...rootCompIds]

	viewerAPI.actions.runInBatch(() => {
		Object.entries(data).forEach(([mapName, mapData]) =>
			Object.entries(mapData).forEach(([itemId, itemData]) => {
				// @ts-ignore
				viewerAPI.data.updateData(mapName, itemId, itemData)
			})
		)

		Object.entries(structure).forEach(([compId, compStructure]) => {
			compStructure.metaData = { pageId: 'masterPage' }
			viewerAPI.structure.updateStructure(compId, compStructure)
		})

		addMasterPageToMiniSites(viewerAPI)

		const siteRoot = viewerAPI.structure.getStructure('site-root')
		viewerAPI.structure.updateStructure('site-root', {
			...siteRoot,
			components: rootCompIds,
		})
	})
	logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_ADD_DATA)

	const componentsLoader = getModule<IComponentsLoader>(ComponentsLoaderSymbol)
	const structureStore = getModule<IStructureStore>(StructureSym)

	const mainStructure = structureStore.get('main_MF')
	structureStore.update({
		main_MF: {
			...mainStructure,
			components: _.without(mainStructure.components, 'SCROLL_TO_BOTTOM', 'SCROLL_TO_TOP'),
		},
	})

	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_COMPS)
	const loadCompPromise = componentsLoader.loadComponents(structureStore.getEntireStore())

	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_FULL_RENDER)

	return hostReact.createElement('div', {
		style: { position: 'relative' },
		ref: async (ref: HTMLElement) => {
			if (ref) {
				await loadCompPromise
				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_COMPS)

				logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_RENDER)
				await render({ target: ref, cssRootCompIds: updatedRootCompIds })
				appDidMount()
				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_RENDER)

				logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_STYLES)
				const stylesPromises = loadStylesForFragment(window.top!.document.head, shouldExcludeStyleSheetFromTop)
				await Promise.all([...stylesPromises])
				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_STYLES)

				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_FULL_RENDER)
				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.GET_VIEWER_FRAGMENT_TOTAL)
				onReady()
			}
		},
		...extraEventsHandlers,
	})
}

const overridePageMinHeight = (
	structure: tbFragmentProps['structure'],
	data: tbFragmentProps['data'],
	pageId: string
) => {
	const propertyQuery = structure[pageId].propertyQuery || `propItem-minisite-${pageId}`
	const pageProperties = data.component_properties[propertyQuery] || {
		id: propertyQuery,
		type: 'PageProperties',
		metaData: {
			schemaVersion: '1.0',
			autoGenerated: false,
			pageId,
		},
		desktop: { minHeight: 1 },
	}
	structure[pageId].propertyQuery = propertyQuery
	data.component_properties[propertyQuery] = pageProperties
}

const overridePagesMinHeight = (
	structure: tbFragmentProps['structure'],
	data: tbFragmentProps['data'],
	pageCompIds: Array<string>
) => {
	pageCompIds.forEach((pageId) => {
		overridePageMinHeight(structure, data, pageId)
	})
}

// should be fixed on editor side - https://github.com/wix-private/thunderbolt/pull/22495#discussion_r837094067
const fixData = (data: tbFragmentProps['data']) => {
	const textTheme = _.get(data, ['theme_data', 'THEME_DATA', 'textTheme'], {})
	_.set(data, ['theme_data', 'THEME_DATA', 'textTheme'], Object.values(textTheme))
}

const fixStructure = (
	structure: tbFragmentProps['structure'],
	data: tbFragmentProps['data'],
	pageCompIds: Array<string>
) => {
	Object.values(structure).forEach((compStructure) => {
		compStructure.metaData = { pageId: 'masterPage' } // todo can remove this once ComponentsCss used for classic?
	})
	fixData(data)
	overridePagesMinHeight(structure, data, pageCompIds)
}

const createFragmentsStatusApi = (rootCompIds: Array<string>) => {
	const promises: Array<Promise<void>> = []
	const resolvers: { [id: string]: Function } = {}
	rootCompIds.forEach((rootCompId) => {
		const { promise, resolver } = createPromise()
		promises.push(promise)
		resolvers[rootCompId] = resolver
	})

	return {
		resolveSinglePromise: (rootCompId: string) => {
			resolvers[rootCompId]()
		},
		allFragmentsPromise: Promise.all(promises),
	}
}

const throwDeprecationErrorsIfNeeded = (experiments: Experiments, structure: Structure, rootCompIds: Array<string>) => {
	if (experiments['specs.thunderbolt.miniSites_stopSupportingMultipleRoots']) {
		if (rootCompIds.length > 1) {
			throw new Error(
				'Deprecated: The use of multiple root components in minisites is no longer supported. Please use a single root component instead.'
			)
		}
		if (structure[rootCompIds[0]]?.type !== 'Page') {
			throw new Error(`Invalid root component. The root component must be of type 'Page'.`)
		}
	}
}

export const buildGetViewerFragments = (
	getDsApisPromise: (viewMode: ViewMode) => Promise<DsApis>,
	logger: ILogger
): GetViewerFragments => async (hostReact: typeof React, props: tbFragmentProps) => {
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.GET_VIEWER_FRAGMENT_TOTAL)
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.INIT_VIEWER_FRAGMENT)
	const { structure, data, onReady, rootCompIds, renderFlags, clientSpecMap, viewMode } = props as tbFragmentProps
	const lowerCaseViewMode = viewMode.toLowerCase() as ViewMode
	const {
		render,
		appDidMount,
		appWillUnmount,
		getViewerAPI,
		getModule,
		initCustomElements,
		getNamedModule,
	} = await getDsApisPromise(lowerCaseViewMode)

	initCustomElements()
	defineRepeaterCustomElement(window.parent)

	const isMobile = lowerCaseViewMode === 'mobile'
	const experiments = getModule<Experiments>(ExperimentsSymbol)

	throwDeprecationErrorsIfNeeded(experiments, structure, rootCompIds)

	const viewerAPI = getViewerAPI()
	if (clientSpecMap) {
		viewerAPI.actions.runInBatch(() => {
			viewerAPI.rendererModel.updateClientSpecMap(clientSpecMap)
		})
	}

	const targetScale = renderFlags?.imagesTargetScale
	if (targetScale) {
		getModule<DSCarmi>(CarmiInstance).minisites.setImageCompsTargetScale(targetScale)
	}

	// Update pageCompId to have the correct requestedPageId in page configs
	getModule<DSCarmi>(CarmiInstance).navigation.setPageCompId('masterPage')

	logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.INIT_VIEWER_FRAGMENT)
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_ADD_DATA)

	const {
		resolveSinglePromise: markSingleFragmentRendered,
		allFragmentsPromise: allFragmentsRenderedPromise,
	} = createFragmentsStatusApi(rootCompIds)
	allFragmentsRenderedPromise.then(appDidMount)

	const {
		resolveSinglePromise: markSingleFragmentUnmounted,
		allFragmentsPromise: allFragmentsUnmountedPromise,
	} = createFragmentsStatusApi(rootCompIds)
	allFragmentsUnmountedPromise.then(appWillUnmount)

	const fragmentReadyListeners: { [rootCompId: string]: Function } = {}
	const triggerFragmentReady = (rootCompId: string) => {
		if (_.isEmpty(rootToSupportedOnReadyComps[rootCompId])) {
			fragmentReadyListeners[rootCompId]()
		}
	}

	const rootToSupportedOnReadyComps = _(structure)
		.pickBy((comp) => onReadyComponents.includes(comp.componentType.split('.').pop()!))
		.groupBy((comp) => comp!.metaData.pageId)
		.mapValues((components) =>
			_(components)
				.keyBy((comp) => comp!.id)
				.mapValues(() => false)
				.value()
		)
		.value()

	const rootToCompsOnReadyProp = _.mapValues(rootToSupportedOnReadyComps, (components, rootCompId) =>
		_.mapValues(components, (__, id) => ({
			onReady: () => {
				delete rootToSupportedOnReadyComps[rootCompId][id]
				triggerFragmentReady(rootCompId)
			},
		}))
	)

	fixStructure(structure, data, rootCompIds)
	viewerAPI.actions.runInBatch(() => {
		viewerAPI.renderFlags.setRenderFlag('isPlayingAllowed', !!renderFlags?.isPlayingAllowed)
		viewerAPI.renderFlags.setRenderFlag(
			'renderFixedPositionBackgrounds',
			!!renderFlags?.renderFixedPositionBackgrounds
		)

		Object.entries(data).forEach(([mapName, mapData]) =>
			Object.entries(mapData).forEach(([itemId, itemData]) => {
				// @ts-ignore
				viewerAPI.data.updateData(mapName, itemId, itemData)
			})
		)

		addMasterPageToMiniSites(viewerAPI)

		Object.entries(structure).forEach(([compId, compStructure]) => {
			viewerAPI.structure.updateStructure(compId, compStructure)
		})
		const siteRoot = viewerAPI.structure.getStructure('site-root')
		rootCompIds.forEach((compId) => {
			viewerAPI.structure.updateStructure(`site-root-${compId}`, {
				...siteRoot,
				id: `site-root-${compId}`,
				components: [compId],
			})
		})
	})
	logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_ADD_DATA)

	const componentsLoader = getModule<IComponentsLoader>(ComponentsLoaderSymbol)
	const structureStore = getModule<IStructureStore>(StructureSym)
	const propsStore = getModule<IPropsStore>(Props)
	const triggersAndReactionsPageConfig = getNamedModule(named(PageFeatureConfigSymbol, 'triggersAndReactions'))

	const mainStructure = structureStore.get('main_MF')
	rootCompIds.forEach((compId) => {
		const compBgId = getPageBackgroundId(compId)
		const hasPageBackground = structureStore.get(compBgId)

		const components = hasPageBackground
			? ['BACKGROUND_GROUP', ...mainStructure.components]
			: [...mainStructure.components]
		const newComponents = components.map((id) => (id === 'site-root' ? `site-root-${compId}` : id))

		structureStore.update({
			[`main_MF-${compId}`]: {
				...mainStructure,
				components: _.without(newComponents, 'SCROLL_TO_BOTTOM', 'SCROLL_TO_TOP'),
			},
			...(hasPageBackground && {
				BACKGROUND_GROUP: {
					componentType: 'BackgroundGroup',
					components: [compBgId],
					pageId: `masterPage`,
				},
			}),
		})
	})
	const onReadyProps = Object.assign({}, ...Object.values(rootToCompsOnReadyProp))
	propsStore.update(onReadyProps)

	const { promise: platformUpdatesPromise, resolver: resolvePlatformPromise } = createPromise()
	if (clientSpecMap) {
		// TODO don't run platform if there are no platform components
		const platformApi = getModule<PlatformDsApi>(PlatformSymbol)
		platformApi
			.runPlatform({
				pageInfo: {
					contextId: 'masterPage_editor_desktop_static',
					pageId: 'masterPage',
				},
			})
			.then(() => resolvePlatformPromise())
	} else {
		resolvePlatformPromise()
	}

	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_COMPS)
	const loadCompPromise = componentsLoader.loadComponents(structureStore.getEntireStore())
	await loadCompPromise
	logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_COMPS)

	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_FULL_RENDER)
	const shouldRenderFragmentsInShadowDom = experiments['specs.thunderbolt.minisitesShadowDom']
	return rootCompIds.map((rootCompId) => {
		return ({ onReady: onFragmentReady } = { onReady }) => {
			const ref = hostReact.useRef<HTMLDivElement>(null)
			hostReact.useEffect(() => () => markSingleFragmentUnmounted(rootCompId), [])

			hostReact.useEffect(() => {
				const renderFragment = async () => {
					if (!ref.current) {
						return
					}
					let targetFragmentContainer = ref.current,
						stylesheetsTarget: HTMLHeadElement | ShadowRoot = window.top!.document.head
					if (shouldRenderFragmentsInShadowDom) {
						const shadow = ref.current.attachShadow({ mode: 'closed' })
						const div = window.parent.document.createElement('div')
						shadow.appendChild(div)
						div.style.all = 'initial'
						targetFragmentContainer = div
						stylesheetsTarget = shadow
					}

					if (shouldRenderFragmentsInShadowDom) {
						const siteCss = propsStore.get('SITE_STYLES').css
						propsStore.update({
							SITE_STYLES: {
								css: siteCss.replace(/:root/g, ':host'),
								target: stylesheetsTarget,
							},
						})
					}

					await platformUpdatesPromise
					fragmentReadyListeners[rootCompId] = () => {
						return onFragmentReady({
							shadowRoot: stylesheetsTarget,
							options: { triggersAndReactions: triggersAndReactionsPageConfig },
						})
					}
					logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_RENDER)
					await render({
						target: targetFragmentContainer,
						rootCompId: `main_MF-${rootCompId}`,
						cssRootCompIds: [MASTER_PAGE_ID, rootCompId],
					})
					markSingleFragmentRendered(rootCompId)
					logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_RENDER)

					logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_STYLES)
					const stylesPromises = shouldRenderFragmentsInShadowDom
						? loadStylesForFragment(stylesheetsTarget)
						: loadStylesForFragment(stylesheetsTarget, shouldExcludeStyleSheetFromTop)
					await Promise.all([...stylesPromises])
					logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_STYLES)

					logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_FULL_RENDER)
					logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.GET_VIEWER_FRAGMENT_TOTAL)

					if (!rootToSupportedOnReadyComps[rootCompId]) {
						triggerFragmentReady(rootCompId)
					}
				}
				renderFragment()
			}, [])

			return (
				<div
					ref={ref}
					style={{ position: 'relative' }}
					className={isMobile ? 'device-mobile-optimized' : ''}
				></div>
			)
		}
	})
}

export const convertToGetViewerFragmentsType = (GetViewerFragmentFunc: GetViewerFragmentOnPage): GetViewerFragments => {
	return async (hostReact: typeof React, props: tbFragmentProps) => {
		const singleElementPromise = GetViewerFragmentFunc(hostReact, props)
		return singleElementPromise.then((singleElement) => [singleElement])
	}
}

export const buildGetViewerFragmentOnPage = (
	getDsApisPromise: (viewMode: ViewMode) => Promise<DsApis>,
	logger: ILogger
): GetViewerFragmentOnPage => async (hostReact: typeof React, props: tbFragmentProps) => {
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.GET_VIEWER_FRAGMENT_TOTAL)
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.INIT_VIEWER_FRAGMENT)

	const { structure, data, onReady, rootCompIds, renderFlags, clientSpecMap, viewMode } = props as tbFragmentProps
	const lowerCaseViewMode = viewMode.toLowerCase() as ViewMode
	const {
		render,
		appDidMount,
		appWillUnmount,
		getViewerAPI,
		getModule,
		initCustomElements,
		getNamedModule,
	} = await getDsApisPromise(lowerCaseViewMode)

	const pageId = rootCompIds[0]
	const contextId = `${pageId}_editor_desktop_static`

	initCustomElements()
	defineRepeaterCustomElement(window.parent)

	const isMobile = lowerCaseViewMode === 'mobile'
	const experiments = getModule<Experiments>(ExperimentsSymbol)

	throwDeprecationErrorsIfNeeded(experiments, structure, rootCompIds)

	const viewerAPI = getViewerAPI()
	if (clientSpecMap) {
		viewerAPI.actions.runInBatch(() => {
			viewerAPI.rendererModel.updateClientSpecMap(clientSpecMap)
		})
	}

	const targetScale = renderFlags?.imagesTargetScale
	if (targetScale) {
		getModule<DSCarmi>(CarmiInstance).minisites.setImageCompsTargetScale(targetScale)
	}

	// Update pageCompId to have the correct requestedPageId in page configs
	getModule<DSCarmi>(CarmiInstance).navigation.setPageCompId(pageId)

	logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.INIT_VIEWER_FRAGMENT)
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_ADD_DATA)

	const { promise: fragmentsRenderedPromise, resolver: markSingleFragmentRendered } = createPromise()
	const { promise: fragmentsUnmountedPromise, resolver: markSingleFragmentUnmounted } = createPromise()
	fragmentsRenderedPromise.then(appDidMount)
	fragmentsUnmountedPromise.then(appWillUnmount)

	const fragmentReadyListeners: { [rootCompId: string]: Function } = {}
	const triggerFragmentReady = (rootCompId: string) => {
		if (_.isEmpty(rootToSupportedOnReadyComps[rootCompId])) {
			fragmentReadyListeners[rootCompId]()
		}
	}

	const rootToSupportedOnReadyComps = _(structure)
		.pickBy((comp) => onReadyComponents.includes(comp.componentType.split('.').pop()!))
		.groupBy((comp) => comp!.metaData.pageId)
		.mapValues((components) =>
			_(components)
				.keyBy((comp) => comp!.id)
				.mapValues(() => false)
				.value()
		)
		.value()

	const rootToCompsOnReadyProp = _.mapValues(rootToSupportedOnReadyComps, (components, rootCompId) =>
		_.mapValues(components, (__, id) => ({
			onReady: () => {
				delete rootToSupportedOnReadyComps[rootCompId][id]
				triggerFragmentReady(rootCompId)
			},
		}))
	)

	fixData(data)
	overridePageMinHeight(structure, data, pageId)

	// update structure and data stores
	viewerAPI.actions.runInBatch(() => {
		viewerAPI.renderFlags.setRenderFlag('isPlayingAllowed', !!renderFlags?.isPlayingAllowed)
		viewerAPI.renderFlags.setRenderFlag(
			'renderFixedPositionBackgrounds',
			!!renderFlags?.renderFixedPositionBackgrounds
		)
		Object.entries(data).forEach(([mapName, mapData]) =>
			Object.entries(mapData).forEach(([itemId, itemData]) => {
				// @ts-ignore
				viewerAPI.data.updateData(mapName, itemId, itemData)
			})
		)

		addMasterPageToMiniSites(viewerAPI)

		Object.entries(structure).forEach(([compId, compStructure]) => {
			viewerAPI.structure.updateStructure(compId, compStructure)
		})
		const siteRoot = viewerAPI.structure.getStructure('site-root')
		viewerAPI.structure.updateStructure(`site-root-${pageId}`, {
			...siteRoot,
			id: `site-root-${pageId}`,
			components: [pageId],
		})
	})

	logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_ADD_DATA)
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_INIT_PAGE)

	const pageInitializer = getModule<IPageInitializer>(PageInitializerSymbol)
	await pageInitializer.initPage({ pageId, contextId })

	logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_INIT_PAGE)

	const structureStore = getModule<IStructureStore>(StructureSym)
	const propsStore = getModule<IPropsStore>(Props)
	const triggersAndReactionsPageConfig = getNamedModule(named(PageFeatureConfigSymbol, 'triggersAndReactions'))

	// Add main-MF and background to rendered tree
	const mainStructure = structureStore.get('main_MF')
	const compBgId = getPageBackgroundId(pageId)
	const hasPageBackground = structureStore.get(compBgId)

	const components = hasPageBackground
		? ['BACKGROUND_GROUP', ...mainStructure.components]
		: [...mainStructure.components]
	const newComponents = components.map((id) => (id === 'site-root' ? `site-root-${pageId}` : id))

	structureStore.update({
		[`main_MF-${pageId}`]: {
			...mainStructure,
			components: _.without(newComponents, 'SCROLL_TO_BOTTOM', 'SCROLL_TO_TOP'),
		},
		...(hasPageBackground && {
			BACKGROUND_GROUP: {
				componentType: 'BackgroundGroup',
				components: [compBgId],
				pageId: MASTER_PAGE_ID,
			},
		}),
	})

	const onReadyProps = Object.assign({}, ...Object.values(rootToCompsOnReadyProp))
	propsStore.update(onReadyProps)

	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_FULL_RENDER)
	const shouldRenderFragmentsInShadowDom = experiments['specs.thunderbolt.minisitesShadowDom']

	return ({ onReady: onFragmentReady } = { onReady }) => {
		const ref = hostReact.useRef<HTMLDivElement>(null)

		hostReact.useEffect(() => () => markSingleFragmentUnmounted(), [])

		hostReact.useEffect(() => {
			const renderFragment = async () => {
				if (!ref.current) {
					return
				}
				let targetFragmentContainer = ref.current,
					stylesheetsTarget: HTMLHeadElement | ShadowRoot = window.top!.document.head
				if (shouldRenderFragmentsInShadowDom) {
					const shadow = ref.current.attachShadow({ mode: 'closed' })
					const div = window.parent.document.createElement('div')
					shadow.appendChild(div)
					div.style.all = 'initial'
					targetFragmentContainer = div
					stylesheetsTarget = shadow
				}

				if (shouldRenderFragmentsInShadowDom) {
					const siteCss = propsStore.get('SITE_STYLES').css
					propsStore.update({
						SITE_STYLES: {
							css: siteCss.replace(/:root/g, ':host'),
							target: stylesheetsTarget,
						},
					})
				}

				fragmentReadyListeners[pageId] = () => {
					return onFragmentReady({
						shadowRoot: stylesheetsTarget,
						options: { triggersAndReactions: triggersAndReactionsPageConfig },
					})
				}
				logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_RENDER)
				await render({
					target: targetFragmentContainer,
					rootCompId: `main_MF-${pageId}`,
					cssRootCompIds: [MASTER_PAGE_ID, pageId],
				})

				markSingleFragmentRendered()

				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_RENDER)

				logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_STYLES)
				const stylesPromises = shouldRenderFragmentsInShadowDom
					? loadStylesForFragment(stylesheetsTarget)
					: loadStylesForFragment(stylesheetsTarget, shouldExcludeStyleSheetFromTop)
				await Promise.all([...stylesPromises])
				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_STYLES)

				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_FULL_RENDER)
				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.GET_VIEWER_FRAGMENT_TOTAL)

				if (!rootToSupportedOnReadyComps[pageId]) {
					triggerFragmentReady(pageId)
				}
			}
			renderFragment()
		}, [])

		return (
			<div ref={ref} style={{ position: 'relative' }} className={isMobile ? 'device-mobile-optimized' : ''}></div>
		)
	}
}
