import _ from 'lodash'
import type { DefaultViewerAPI } from 'thunderbolt-viewer-manager-types'

export const createDefaultViewerAPI = () => {
	const defaultViewerAPI: DefaultViewerAPI = {
		viewerConfig: { viewerName: 'tb', viewerVersion: '0' },
		legacy: {
			updateBiData: _.noop,
			reportBI: _.noop,
			reportBIError: _.noop,
		},
		selectiveDownload: {
			downloadCompClasses: () => Promise.resolve(),
		},
		// ne need to implement currently: https://wix.slack.com/archives/C01AKK70JNA/p1620213667123300?thread_ts=1620198426.111700&cid=C01AKK70JNA
		wixCode: {
			setWixCodeDsModel: _.noop,
			getWixCodeDsModel: () => ({}),
		},
		ghostables: {
			getGhostStructure: () => ({}),
			setGhostStructure: _.noop,
			getGhostControllers: () => ({}),
			setGhostControllers: _.noop,
		},
		platform: {
			getAppInstance: () => ({}),
			reloadAppsContainer: _.noop,
			flushDsBeforeLoadMessagesQueue: _.noop,
		},
		media: {
			registerStateChange: _.noop,
			unregisterStateChange: _.noop,
		},
	}

	return defaultViewerAPI
}
