const MOBILE_APP_BRANCH = '1a04cd63-178d-4e59-afd2-ddd6c4be5f00'

export const isMobileAppBuilder = (url: string, componentsOnSite?: { [compType: string]: any }): boolean => {
	if (process.env.PACKAGE_NAME === 'thunderbolt-ds') {
		const queryParams = new URL(url).searchParams
		const isMobileAppSnapshotterUrl =
			queryParams.get('dsOrigin') === 'snapshotter' && queryParams.get('branchId') === MOBILE_APP_BRANCH
		try {
			const isMobileAppBuilderUrl =
				JSON.parse(queryParams.get('commonConfig') || '{}')?.host === 'MOBILE_APP_BUILDER'
			return isMobileAppSnapshotterUrl || isMobileAppBuilderUrl
		} catch (e) {
			console.error('Failed to parse common config', e)
			return false
		}
	} else {
		return !!Object.keys(componentsOnSite || {}).find((compType) => {
			return compType.startsWith('mobui.')
		})
	}
}
